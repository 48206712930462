import MobileDetect from 'mobile-detect';
import cookies from '../cookies';
import formElements from './overlayFormElements';
import urlUtil from '../urlUtil';
import trackOverlayInit from './trackOverlay';
import integrationInit from '../integration';
import sessionOverlayInit from './sessionOverlay';
import timeOverlayInit from './timeOverlay';
import uuidUtil from '../uuidUtil';
import {
    OVERLAY_PARAM_DEBUG,
    OVERLAY_PARAM_SHOW_OVERLAY,
    OVERLAY_PARAM_INLINE_STYLE_DELAY,
    OVERLAY_PARAM_PAGEVIEWS_OVERRIDE,
} from '../constants';

let config;
let trackOverlay;
let integration;
let urlUtility;
let isPreview = false;
let redirectUrl;
let currentOverlay = null;
let currentExitIntent = null;
let sessionOverlay;
let timeOverlay;

const { DateTime } = require('luxon');
const ENTER_KEY = 13;
const ESC_KEY = 27;
const OVERLAY_TIMEOUT = 5000;
const EMAIL_INPUT_SELECTOR = '#sailthru-user-acquisition-email';
const PHONE_INPUT_SELECTOR = '#sailthru-user-acquisition-phone';
const SMS_MARKETING_CHECKBOX_SELECTOR = '#sailthru-user-acquisition-sms-marketing';
const SMS_TRANSACTION_CHECKBOX_SELECTOR = '#sailthru-user-acquisition-sms-transaction';
const SMS_CONSENT_OPT_IN = 'opt-in';
const SMS_CONSENT_OPT_OUT = 'opt-out';

function disableBackground() {
    if (
        document.body &&
        document.body.classList
    ) {
        document.body.classList.add('sailthru-overlay-no-scroll');
    }
}

function enableBackground() {
    if (
        document.body &&
        document.body.classList
    ) {
        document.body.classList.remove('sailthru-overlay-no-scroll');
    }
}

function hideOverlay() {
    enableBackground();
    const overlayContainerElems = document.getElementsByClassName('sailthru-overlay-container');
    for (let ix = 0; ix < overlayContainerElems.length; ix += 1) {
        const overlayContainerElem = overlayContainerElems[ix];
        overlayContainerElem.parentElement.removeChild(overlayContainerElem);
    }
    if (
        document.getElementsByTagName('body') &&
        document.getElementsByTagName('body')[0] &&
        document.getElementsByTagName('body')[0].classList
    ) {
        document.getElementsByTagName('body')[0].classList.remove('sailthru-pushdown');
        document.getElementsByTagName('body')[0].classList.remove('sailthru-pushdown-animation');
    }

    if (currentOverlay && currentOverlay.overlay_id && sessionOverlay.getStickyOverlayId() === currentOverlay.overlay_id) {
        sessionOverlay.dismiss(currentOverlay.overlay_id);
    }

    currentOverlay = null;
}

function hideOverlayWithDelay(milliseconds) {
    setTimeout(() => {
        hideOverlay();
    }, milliseconds);
}

function removeScriptTags(inputString) {
    return inputString.replace(/<\/*\s*script.*?>/ig, '');
}

function validateEmail(email) {
    // https://github.com/php/php-src/blob/250938e2d35fc54161a18167b7901c5e3b574371/ext/filter/logical_filters.c#L575-L627
    // Same regex used in the backend. See /dev/jvm/commons/src/main/java/com/sailthru/commons/util/Validate.java
    const emailRegex = /^(?!(?:(?:\x22?\x5C[\x00-\x7E]\x22?)|(?:\x22?[^\x5C\x22]\x22?)){255,})(?!(?:(?:\x22?\x5C[\x00-\x7E]\x22?)|(?:\x22?[^\x5C\x22]\x22?)){65,}@)(?:(?:[\x21\x23-\x27\x2A\x2B\x2D\x2F-\x39\x3D\x3F\x5E-\x7E]+)|(?:\x22(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|(?:\x5C[\x00-\x7F]))*\x22))(?:\.(?:(?:[\x21\x23-\x27\x2A\x2B\x2D\x2F-\x39\x3D\x3F\x5E-\x7E]+)|(?:\x22(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|(?:\x5C[\x00-\x7F]))*\x22)))*@(?:(?:(?!.*[^.]{64,})(?:(?:(?:xn--)?[a-z0-9]+(?:-[a-z0-9]+)*\.){1,126}){1,}(?:(?:[a-z][a-z0-9]*)|(?:(?:xn--)[a-z0-9]+))(?:-[a-z0-9]+)*)|(?:\[(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){7})|(?:(?!(?:.*[a-f0-9][:\]]){7,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?::(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?)))|(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){5}:)|(?:(?!(?:.*[a-f0-9]:){5,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3})?::(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3}:)?)))?(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))(?:\.(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))){3}))\]))$/i;
    return emailRegex.test(email);
}

/*
 * Arguments: phone number (string or number)
 * Returns: formatted phone number (string), or null if invalid
 *
 * Formats phone numbers in a manner consistent with the public User API.
 * - Non-digit characters are stripped out.
 * - Phone numbers must contain at least 10 digits.
 * - If the phone number is exactly 10 digits, a US country code is assumed and '1' is prepended.
 * - '+' character is prepended to all phone numbers.
 * - If the phone number cannot be valid (because it contains too few digits), null is returned.
 *
 * This logic emulates Util_Validate::sms in PHP repo
 * (https://github.com/sailthru/php/blob/3999ccd6348511ff9391ee9685b1fdc3c284ec12/commons/classes/Util/Validate.php#L334)
 *
 */
function validateAndFormatPhoneNumber(phone) {
    let numberToTest = phone;

    if (typeof numberToTest === 'number') {
        numberToTest = numberToTest.toString();
    }

    let cleanedNumber = numberToTest.replace(/[^0-9]/g, '');

    if (cleanedNumber.length < 10) {
        return null;
    }
    if (cleanedNumber.length === 10) {
        cleanedNumber = `1${cleanedNumber}`;
    }

    return `+${cleanedNumber}`;
}

function getRowColElemFromClass(classNames) {
    const elemClassRegex = /sailthru(?:_\d){3}/;
    const match = classNames.match(elemClassRegex);
    if (!match) {
        return { row: null, col: null, elem: null };
    }

    const [row, col, elem] = match[0].match(/\d/g);

    return { row, col, elem };
}

function showInputValidationError(inputSelector) {
    if (!inputSelector) {
        console.warn('showInputValidationError:: No selector was provided');
        return null;
    }

    const inputElem = document.querySelector(inputSelector);
    if (!inputElem) {
        console.warn(`hideInputValidationError:: ${inputSelector} was not found`);
        return null;
    }

    let inputElemClasses = inputElem.className;

    const { row, col, elem } = getRowColElemFromClass(inputElemClasses);
    // NOTE: get element with error class name and finishing id with row-col-elem corresponding to the input
    const errorElem = document.querySelector(`div[id$='error-${row}-${col}-${elem}'].sailthru-overlay-validation-error`);
    if (errorElem) {
        errorElem.style.display = 'block';
    }

    if (inputElemClasses.indexOf('has-error') < 0) {
        if (!inputElemClasses.endsWith(' ')) {
            inputElemClasses += ' ';
        }
        inputElemClasses += 'has-error';
    }
    inputElem.className = inputElemClasses;
    return null;
}

function hideInputValidationError(inputSelector) {
    if (!inputSelector) {
        console.warn('hideInputValidationError:: No selector was provided');
        return null;
    }

    const inputElem = document.querySelector(inputSelector);

    if (!inputElem) {
        console.warn(`hideInputValidationError:: ${inputSelector} was not found`);
        return null;
    }

    let inputElemClasses = inputElem.className;

    const { row, col, elem } = getRowColElemFromClass(inputElemClasses);
    // NOTE: get element with error class name and finishing id with row-col-elem corresponding to the input
    const errorElem = document.querySelector(`div[id$='error-${row}-${col}-${elem}'].sailthru-overlay-validation-error`);
    if (errorElem) {
        errorElem.style.display = 'none';
    }

    inputElemClasses = inputElemClasses.replace(/[^\s]has-error/g, '');
    inputElem.className = inputElemClasses;

    return null;
}

function showPostClickMessage() {
    document.getElementsByClassName('sailthru-overlay')[0].style.display = 'none';
    document.getElementsByClassName('sailthru-overlay-post-click')[0].style.display = 'inherit';
}

function getSafeInputValue(inputSelector) {
    const inputValue = document.querySelector(inputSelector).value;
    return removeScriptTags(inputValue);
}

function generateRedirectUrl(postClickUrl, keys = {}, vars) {
    let url = postClickUrl;
    let encodedUrlParams = '';
    const hasQuery = url.indexOf('?') !== -1;
    const hasHash = url.indexOf('#') !== -1;
    const encodedEmail = !!keys.email && encodeURIComponent(keys.email);

    if (encodedEmail) {
        encodedUrlParams += `em=${encodedEmail}`;
    }

    encodedUrlParams = Object.entries(vars).reduce((acc, [key, val]) => {
        let accQueryString = acc;
        if (acc) {
            accQueryString += '&';
        }
        return `${accQueryString}${key}=${encodeURIComponent(val)}`;
    }, encodedUrlParams);

    if (hasQuery && hasHash) {
        url = url.replace('#', `&${encodedUrlParams}#`);
    } else if (hasQuery && !hasHash) {
        url = `${url}&${encodedUrlParams}`;
    } else if (!hasQuery && hasHash) {
        url = url.replace('#', `?${encodedUrlParams}#`);
    } else if (!hasQuery && !hasHash) {
        url = `${url}?${encodedUrlParams}`;
    }
    return url;
}

function handlePostClick(postClick, keys, vars) {
    if (postClick && postClick.redirect && postClick.redirect.active && postClick.redirect.url) {
        const url = generateRedirectUrl(postClick.redirect.url, keys, vars);
        if (!postClick.redirect.new_tab) {
            window.location.assign(url);
        }
        hideOverlay();
        return `Redirecting to ${url}`;
    } else if (postClick && postClick.message && postClick.message.active && postClick.message.text) {
        showPostClickMessage();
        hideOverlayWithDelay(OVERLAY_TIMEOUT);
        return `Display Message: ${postClick.message.text}`;
    }

    hideOverlay();
    return 'No Post-click Action';
}

function getOnSuccess(overlay, keys, vars) {
    return function onSuccess(response) {
        if (urlUtility.urlFragmentParams[OVERLAY_PARAM_DEBUG]) {
            console.log('userSignUp SUCCESS', response);
        }

        trackOverlay.conversion(overlay, keys);

        let onSignupResult = -1;
        if (typeof (config.onSignupSuccess) === 'function') {
            try {
                onSignupResult = config.onSignupSuccess({
                    ...keys,
                    vars: {
                        ...vars,
                    },
                    response,
                });

                if (onSignupResult == 0) {
                    hideOverlay();
                    return 0;
                }
            } catch (callbackErr) {
                console.log('onSignupSuccess encountered an error:', callbackErr);
                onSignupResult = 0;
            }
        }


        const result = handlePostClick(overlay.post_click, keys, vars);
        if (urlUtility.urlFragmentParams[OVERLAY_PARAM_DEBUG]) {
            console.log(`onSignupSuccess returned ${onSignupResult} with post-click: ${result}`);
        }
        return onSignupResult;
    };
}

function getOnError(keys) {
    return function onError(response) {
        console.log('userSignUp ERROR', response);

        let onSignupResult = -1;
        if (typeof (config.onSignupError) === 'function') {
            try {
                onSignupResult = config.onSignupError({
                    ...keys,
                    error: response.error || 'There was an error with your request',
                });
            } catch (callbackErr) {
                console.log('onSignupError encountered an error:', callbackErr);
                onSignupResult = 0;
            }
        }
        hideOverlay();
        console.log(`onSignupError returned ${onSignupResult}`);
        return onSignupResult;
    };
}

function getVars(overlay) {
    const userVars = formElements.getVars();
    return {
        ...userVars,
        source: overlay.acquisition_source || 'overlay',
    };
}

function onOverlayButtonClick(overlay) {
    const destinationLists = overlay.destination_lists;
    const keys = {};

    const emailElem = document.querySelector(EMAIL_INPUT_SELECTOR);
    const hasEmailInput = !!emailElem;
    let safeEmail = null;

    const phoneElem = document.querySelector(PHONE_INPUT_SELECTOR);
    const hasPhoneInput = !!phoneElem;
    let safePhone = null;
    let validPhone = null;
    let hasPhoneNumber = false;

    if (hasEmailInput) {
        safeEmail = getSafeInputValue(EMAIL_INPUT_SELECTOR);

        if (!(safeEmail && validateEmail(safeEmail))) {
            showInputValidationError(EMAIL_INPUT_SELECTOR);
            // NOTE: should we handle the focus inside showInputValidationError?
            emailElem.focus();
            console.error('Invalid Email');

            return false;
        }

        keys.email = safeEmail;
    }

    if (hasPhoneInput) {
        safePhone = getSafeInputValue(PHONE_INPUT_SELECTOR);
        hasPhoneNumber = safePhone !== '';
        validPhone = validateAndFormatPhoneNumber(safePhone);
        if (hasPhoneNumber || !hasEmailInput) {
            if (!validPhone) {
                showInputValidationError(PHONE_INPUT_SELECTOR);
                phoneElem.focus();
                console.error('Invalid Phone');

                return false;
            }
            keys.sms = validPhone;
        }
    }

    hideInputValidationError(PHONE_INPUT_SELECTOR);
    hideInputValidationError(EMAIL_INPUT_SELECTOR);
    const key = hasEmailInput ? 'email' : 'sms';
    const id = hasEmailInput ? keys.email : keys.sms;

    const isConfirmOptIn = overlay.template !== '';
    const idAndKey = {
        id,
        key,
    };

    const smsConsentValues = {};
    const smsMarketingConsentElem = document.querySelector(SMS_MARKETING_CHECKBOX_SELECTOR);
    const smsTransactionConsentElem = document.querySelector(SMS_TRANSACTION_CHECKBOX_SELECTOR);

    // NOTE: set sms consent values if checkboxes present
    if (hasPhoneNumber && smsMarketingConsentElem) {
        smsConsentValues.sms_marketing_status = smsMarketingConsentElem.checked ? SMS_CONSENT_OPT_IN : SMS_CONSENT_OPT_OUT;
    }
    if (hasPhoneNumber && smsTransactionConsentElem) {
        smsConsentValues.sms_transactional_status = smsTransactionConsentElem.checked ? SMS_CONSENT_OPT_IN : SMS_CONSENT_OPT_OUT;
    }

    const vars = getVars(overlay);
    const onSuccess = getOnSuccess(overlay, keys, vars);
    const onError = getOnError(keys);

    if (isConfirmOptIn && keys.email) {
        const template = {
            name: overlay.template,
            vars: {
                signup_lists: destinationLists,
            },
        };

        return integration.userSignUpConfirmedOptIn(idAndKey, {
            keys,
            ...smsConsentValues,
            vars,
            template,
            onSuccess,
            onError,
        });
    }

    const lists = {};

    if (destinationLists) {
        destinationLists.forEach((list) => {
            lists[list] = 1;
        });
    }

    return integration.userSignUp(idAndKey, {
        keys,
        ...smsConsentValues, // smsConsentValues are spread into first level
        lists,
        vars,
        onSuccess,
        onError,
    });
}

function isShowOverlaySet() {
    return urlUtility.urlFragmentParams[OVERLAY_PARAM_SHOW_OVERLAY] === '1';
}

function updateLocalStorage(localStorage, strKey, strValue) {
    try {
        localStorage.setItem(strKey, strValue);
    } catch(e) {
        if (e.name === 'QuotaExceededError') {
            console.error('LocalStorage is full. Making space and retrying update');
            // removing oldest data(assuming new entries are appended to end) to make space for new data
            strValue = strValue.substr(1 + strValue.indexOf('|'));
            try {
                localStorage.setItem(strKey, strValue);
            } catch(e) {
                console.error('Failed updating local storage');
                throw e;
            }
        }
    }
}

/**
 * Returns lastSeen date time from localStorage if overlay exists
 * Return null if not found
 *
 * @param {localStorage to check overlay information from} localStorage
 * @param {overlayId to search for} overlayId
 */
function getDisplayedOverlayRecord(localStorage, overlayId) {
    const lastSeenOverlayDetails = localStorage.getItem('sailthru-overlay-info') || '';
    if(lastSeenOverlayDetails === '' || lastSeenOverlayDetails.indexOf(overlayId) === -1) {
        return null;
    }
    const matchPair = lastSeenOverlayDetails.split('|').find(pair => pair.startsWith(overlayId));
    return DateTime.fromISO(matchPair.split('#')[1]);
}

/**
 *
 * Called before displaying an overlay.
 * This will get LocalStorage sailthru-overlay-hit-count, it contains - [ overlayId:pageview hit number ]
 *
 * @param {localStorage} localStorage
 * @param {overlayId} overlayId
 */

function getPreviousPageviewCountForOverlay(localStorage, overlayId) {
    let pageviewCount = 0;
    const lastSeenOverlayDetails = localStorage.getItem('sailthru-overlay-hit-count') || '';
    if(lastSeenOverlayDetails.indexOf(overlayId) >= 0) {
        const matchPair = lastSeenOverlayDetails.split('|').find(pair => pair.startsWith(overlayId));
        pageviewCount = matchPair.split('#')[1];
    }
    return Number(pageviewCount);
}

function updateOverlayDetailsWithPageviewCount(localStorage, overlayId) {
    const currentPageviewCount = cookies.getPageViewsCount();
    const lastSeenOverlayDetails = localStorage.getItem('sailthru-overlay-hit-count') || '';
    const newOverlayDetails = `${overlayId}#${currentPageviewCount}|`;
    if(lastSeenOverlayDetails.indexOf(overlayId) === -1) {
        updateLocalStorage(localStorage, 'sailthru-overlay-hit-count', `${lastSeenOverlayDetails}${newOverlayDetails}`);
    } else {
        const matchPair = lastSeenOverlayDetails.split('|').find(pair => pair.startsWith(overlayId));
        const newDetails = lastSeenOverlayDetails.replace(`${overlayId}#${matchPair.split('#')[1]}|`, `${newOverlayDetails}`);
        updateLocalStorage(localStorage, 'sailthru-overlay-hit-count', newDetails);
    }
}

function recordOverlayDisplay(localStorage, overlayId) {
    const previousOverlayDetails = localStorage.getItem('sailthru-overlay-info') || '';
    const newOverlayDetails = `${overlayId}#${DateTime.utc().toISO()}|`;
    if(previousOverlayDetails.indexOf(overlayId) === -1) {
        updateLocalStorage(localStorage, 'sailthru-overlay-info', `${previousOverlayDetails}${newOverlayDetails}`);
    } else {
        const matchPair = previousOverlayDetails.split('|').find(pair => pair.startsWith(overlayId));
        const newDetails = previousOverlayDetails.replace(`${overlayId}#${matchPair.split('#')[1]}|`, `${newOverlayDetails}`);
        updateLocalStorage(localStorage, 'sailthru-overlay-info', newDetails);
    }
}

/**
 * This function will evaluate whether frequency capping rules are satisifed for the overlay.
 * If it returns a thruthy result, it indicates that frequency capping rules are satisfied.
 * If the returned result is a function, it is a callback that must be invoked when the overlay
 * is shown.
 */
function matchFrequency(frequency, frequencyValue, overlayId, mockStorage) {
    const localStorage = (mockStorage) || window.localStorage;
    const overlayCookie = cookies.read('sailthru_overlays');
    let overlayLastSeenOnDate = getDisplayedOverlayRecord(localStorage, overlayId); // Returns overlay last seen date from localstorage
    const currentDateTime = DateTime.utc();

    if (isShowOverlaySet()) {
        return true;
    }

    switch (frequency) {
        case 'every-pageview':
            return true;
        case 'once-visit':
            if (overlayCookie.indexOf(overlayId) === -1) {
                return () => {
                    cookies.create('sailthru_overlays', `${overlayId}${cookies.read('sailthru_overlays')}`, 30);
                };
            }
            return false;
        case 'once-ever':
            if (localStorage.getItem('sailthruOverlay')) {
                if (localStorage.getItem('sailthruOverlay').indexOf(overlayId) === -1) {
                    return () => {
                        try {
                            localStorage.setItem('sailthruOverlay', `${overlayId}${localStorage.getItem('sailthruOverlay')}`);
                        } catch (e) {
                            localStorage.setItem('sailthruOverlay', `${overlayId}${localStorage.getItem('sailthruOverlay').slice(0, -36)}`);
                        }
                    };
                }
                return false;
            }
            return () => {
                localStorage.setItem('sailthruOverlay', overlayId);
            };
        case 'once-month':
            if(overlayLastSeenOnDate === null) { // If Last seen date is null or never seen show overlay
                return () => {
                    recordOverlayDisplay(localStorage, overlayId); // Update latest details in localstorage
                };
            }
            const nextToBeShownDate = overlayLastSeenOnDate.plus({ days: 31 }).setZone('utc');
            const lastDateOfNextMonth = overlayLastSeenOnDate.plus({ months: 1 }).endOf('month');
            if(currentDateTime >= DateTime.min(lastDateOfNextMonth, nextToBeShownDate)) {
                return () => {
                    recordOverlayDisplay(localStorage, overlayId);
                };
            }
            return false;
        case 'once-week': // If no last seen date present then show overlay OR CurrentDate week Number > lastDate week Number then Show overlay (Here week calculated from Sunday to Saturday)
            const startOfWeek = currentDateTime.minus({ days: (currentDateTime.weekday % 7) }).startOf('day');
            if(startOfWeek > overlayLastSeenOnDate) {
                return () => {
                    recordOverlayDisplay(localStorage, overlayId);
                };
            }
            return false;
        case 'once-n-days': // If Last seen on date is null then show overlay OR Current datetime >= overlay last seen datetime + n days frequency then show overlay
            if(overlayLastSeenOnDate == null || currentDateTime >= overlayLastSeenOnDate.plus({ days: frequencyValue })) {
                return () => {
                    recordOverlayDisplay(localStorage, overlayId);
                };
            }
            return false;
        case 'every-n-pageviews': // If Current Pageview count >= Previous Pageview count + frequencyvalue then show overlay
            if(cookies.getPageViewsCount() >= (getPreviousPageviewCountForOverlay(localStorage, overlayId) + frequencyValue)) {
                return () => {
                    updateOverlayDetailsWithPageviewCount(localStorage, overlayId);
                    recordOverlayDisplay(localStorage, overlayId);
                };
            }
            return false;
        default:
            return false;
    }
}

function isDeviceMobile() {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = md.mobile() || md.phone() || md.tablet();
    return isMobile;
}

function matchTargetDevice(targetDevice) {
    const isMobile = isDeviceMobile();
    if (targetDevice === 'mobile-only' && !isMobile) {
        return false;
    }
    if (targetDevice === 'desktop-only' && isMobile) {
        return false;
    }
    return true;
}

function parseHtmlStringAndAddToDomBody(html) {
    const DOMParser = new window.DOMParser();
    document.body.appendChild(DOMParser.parseFromString(html, 'text/html').body.firstChild);
}

function onEmailInputFocus() {
    hideInputValidationError(EMAIL_INPUT_SELECTOR);
}

function onPhoneInputFocus() {
    hideInputValidationError(PHONE_INPUT_SELECTOR);
}

function addCallToActionListeners(overlay) {
    const overlayButton = document.querySelector('#sailthru-overlay-call-to-action');
    const overlayEmailInput = document.querySelector(EMAIL_INPUT_SELECTOR);
    const overlayPhoneInput = document.querySelector(PHONE_INPUT_SELECTOR);

    function onInputListener() {
        const postClick = overlay.post_click;
        if (postClick && postClick.redirect && postClick.redirect.active
            && postClick.redirect.new_tab && validateEmail(this.value)) {
            const vars = getVars(overlay);
            const safeEmail = getSafeInputValue(EMAIL_INPUT_SELECTOR);
            const keys = { email: safeEmail };
            redirectUrl = generateRedirectUrl(postClick.redirect.url, keys, vars);
            overlayButton.href = redirectUrl;
            overlayButton.target = '_blank';
        } else {
            overlayButton.removeAttribute('href');
            overlayButton.removeAttribute('target');
        }
    }

    if (overlayButton) {
        overlayButton.addEventListener('click', () => {
            onOverlayButtonClick(overlay);
        });
        // TODO: do we need the same listener for overlayPhoneInput?
        // This listener is needed to prevent pop-up blockers for post_click open in new_tab option
        if (overlayEmailInput) {
            overlayEmailInput.addEventListener('input', onInputListener);
        }
    }

    if (overlayEmailInput) {
        overlayEmailInput.addEventListener('keydown', (event) => {
            onEmailInputFocus();
            const key = event.which || event.keyCode;
            if (key === ENTER_KEY) {
                document.querySelector('#sailthru-overlay-call-to-action').click();
            }
        });
    }
    if (overlayPhoneInput) {
        overlayPhoneInput.addEventListener('keydown', (event) => {
            onPhoneInputFocus();
            const key = event.which || event.keyCode;
            if (key === ENTER_KEY) {
                document.querySelector('#sailthru-overlay-call-to-action').click();
            }
        });
    }
}

function addPostClickCloseListeners() {
    const postClickContainer = document.querySelector('.sailthru-overlay-post-click');
    if (!postClickContainer) {
        return;
    }
    const postClickCloseButtons = postClickContainer.querySelectorAll('.sailthru-overlay-close');

    if (postClickCloseButtons) {
        for (let i = 0; i < postClickCloseButtons.length; i += 1) {
            postClickCloseButtons[i].addEventListener('click', () => {
                hideOverlay();
            });
        }
    }

    document.addEventListener('keydown', (event) => {
        const key = event.which || event.keyCode;
        const isVisible = window.getComputedStyle(postClickContainer).getPropertyValue('display') !== 'none';
        if (key === ESC_KEY && isVisible) {
            hideOverlay();
        }
    });
}

function addAllCloseListeners() {
    const overlayCloseButtons = document.querySelectorAll('.sailthru-overlay-close');
    if (overlayCloseButtons) {
        for (let i = 0; i < overlayCloseButtons.length; i += 1) {
            overlayCloseButtons[i].addEventListener('click', () => {
                hideOverlay();
            });
        }
    }

    document.addEventListener('keydown', (event) => {
        const key = event.which || event.keyCode;
        if (key === ESC_KEY) {
            hideOverlay();
        }
    });
}

function addLinkListeners(overlay) {
    const elements = document.getElementById('sailthru-overlay-container').getElementsByTagName('a');
    const onClickListener = () => {
        trackOverlay.click(overlay);
        hideOverlay();
    };

    for (let i = 0, len = elements.length; i < len; i += 1) {
        if (!elements[i].id || (elements[i].id !== 'sailthru-overlay-call-to-action')) {
            elements[i].onclick = onClickListener;
        }
    }
}

function displayOverlayHTML(overlay, mouseLeaveHandler) {
    if (currentOverlay) {
        return;
    }

    currentOverlay = overlay;

    const prevOverlayElems = document.getElementsByClassName('sailthru-overlay');
    for (let ix = prevOverlayElems.length - 1; ix >= 0; ix -= 1) {
        try {
            document.body.removeChild(prevOverlayElems[ix]);
        } catch (ex) {
            // Swallow this exception.
        }
    }

    parseHtmlStringAndAddToDomBody(overlay.html);
    disableBackground();

    if (overlay.allow_close === false) {
        addPostClickCloseListeners();
    } else {
        addAllCloseListeners();
    }
    addLinkListeners(overlay);
    addCallToActionListeners(overlay);

    if (!isPreview) {
        trackOverlay.view(overlay);
    }
    if (overlay.exit_intent) {
        document.getElementsByClassName('sailthru-overlay')[0].classList.add('sailthru-overlay-animation');
        document.documentElement.removeEventListener('mouseleave', mouseLeaveHandler);
    }

    const defaultTransition = overlay.bar_transition === 'Default';
    const noTransition = overlay.bar_transition === 'None';
    const inlineFlow = overlay.bar_flow === 'Inline';
    const fixedFlow = overlay.bar_flow === 'Fixed';
    const positionTop = overlay.position === 'top';

    if (defaultTransition && inlineFlow && positionTop) {
        document.getElementsByClassName('sailthru-overlay')[0].classList.add('sailthru-slidedown');
        document.getElementsByTagName('body')[0].classList.add('sailthru-pushdown-animation');
    } else if (defaultTransition && fixedFlow && positionTop) {
        document.getElementsByClassName('sailthru-overlay')[0].classList.add('sailthru-slidedown');
    } else if (defaultTransition && fixedFlow) {
        document.getElementsByClassName('sailthru-overlay')[0].classList.add('sailthru-slideup');
    } else if (noTransition && inlineFlow) {
        document.getElementsByTagName('body')[0].classList.add('sailthru-pushdown');
    }

    /*
    The following is a work-around to IE11 failing to display div background image
    that's specified via computed CSS.  Setting it explicitly as an inline style
    fixes the issue on Windows 8.1 and 10.  For extra safety, it's being done here
    both synchronously and asynchronously with a timeout.
    */
    const debug = urlUtility.urlFragmentParams[OVERLAY_PARAM_DEBUG];
    const inlineStyleDelayMsStr = urlUtility.urlFragmentParams[OVERLAY_PARAM_INLINE_STYLE_DELAY];
    const inlineStyleDelayMs = inlineStyleDelayMsStr && parseInt(inlineStyleDelayMsStr) || 50;
    const inlineStyleWorker = () => {
        // This will ensure we only apply this to IE 11.
        if (window.navigator.userAgent.indexOf('Trident') < 0) {
            return;
        }
        const overlayElem = document.getElementsByClassName('sailthru-overlay')[0];
        const computedBgImage = window.getComputedStyle(overlayElem, null)['background-image'];
        if (computedBgImage) {
            overlayElem.style['background-image'] = computedBgImage;
            if (debug) {
                console.log(`Setting background-image to ${computedBgImage}.`);
            }
        }

        const computedHeight = window.getComputedStyle(overlayElem, null)['height'];
        if (debug) {
            console.log(`Setting height to ${computedHeight}.`);
        }
        overlayElem.style['height'] = computedHeight;
    };
    inlineStyleWorker();
    if (debug) {
        console.log(`Scheduling inline CSS resets after ${inlineStyleDelayMs} ms.`);
    }
    setTimeout(inlineStyleWorker, inlineStyleDelayMs);
}

function isTargetMatch(overlay) {
    if (!matchTargetDevice(overlay.target_device)) {
        return false;
    }
    const frequencyVal = Number(overlay.frequency_value);
    const frequencyResult = matchFrequency(overlay.frequency, isNaN(frequencyVal) ? 0 : frequencyVal, overlay.overlay_id);
    if (!frequencyResult) {
        return false;
    }

    const onBeforeOverlayRender = config.onBeforeOverlayRender;
    if (typeof(onBeforeOverlayRender) === 'function') {
        try {
            if (onBeforeOverlayRender(overlay) === false) {
                return false;
            }
        } catch(ex) {
            console.log('Unexpected error in onBeforeOverlayRender function.', ex);
        }
    }

    if (typeof(frequencyResult) === 'function') {
        frequencyResult();
    }
    if (overlay.renderhref && overlay.renderhref != document.location.href) {
        return false;
    }
    return true;
}

function displayPersonalizedOverlayHTML(overlay, mouseLeaveHandler) {
    if (isPreview || isTargetMatch(overlay)) {
        displayOverlayHTML(overlay, mouseLeaveHandler);
    }
}

const addExitIntentListener = (overlay) => {
    currentExitIntent = overlay;
    const mouseLeaveHandler = (event) => {
        const sensitivity = 20;
        if (event.clientY > sensitivity || currentExitIntent != overlay) {
            return;
        }
        displayPersonalizedOverlayHTML(overlay, mouseLeaveHandler);
    };
    return mouseLeaveHandler;
};

function renderOverlay(overlay) {
    if (overlay.exit_intent) {
        const exitIntentHandler = addExitIntentListener(overlay);
        document.documentElement.addEventListener('mouseleave', exitIntentHandler);
    } else if (overlay.timer) {
        timeOverlay.renderTimeDelayedOverlay(overlay);
    } else {
        displayPersonalizedOverlayHTML(overlay);
    }
    return overlay;
}

function renderOverlays(overlays) {
    const newOverlays = {};
    Object.keys(overlays).forEach((key) => {
	if (key != "renderhref") {
            const overlay = overlays[key];
	    if (overlays.renderhref && overlay) {
		overlay.renderhref = overlays.renderhref;
	    }
            renderOverlay(overlay);
            newOverlays[key] = overlay;
	}
    });
    return newOverlays;
}

function isPageviewsOverrideSet() {
    const anchor = `${OVERLAY_PARAM_PAGEVIEWS_OVERRIDE}=1`;
    const result = window.location.hash.indexOf(anchor) != -1;
    if (result) {
        console.log(`Overriding pageviews count with anchor "${anchor}".`);
    }
    return result;
}

function getOncePerVisitOverlayList() {
    const overlayCookie = cookies.read('sailthru_overlays');
    return uuidUtil.parseUuidsList(overlayCookie);
}

function getOnceEverOverlayList(mockStorage) {
    const localStorage = mockStorage || window.localStorage;
    const localStorageOverlays = localStorage.getItem('sailthruOverlay');
    return uuidUtil.parseUuidsList(localStorageOverlays);
}

function getUrlParameters() {
    let params = [];

    const previewId = urlUtility.getPreviewIdFromAnchor();
    if (previewId) {
        console.log(`previewing overlay ${previewId}`);
        params.push(`preview=${previewId}`);
    }

    const pageviewsCount = isPageviewsOverrideSet() ? '10000' : cookies.read('sailthru_pageviews');
    if (pageviewsCount) {
        params.push(`pageviews=${pageviewsCount}`);
    }

    const isMobile = isDeviceMobile() ? '1' : '0';
    params.push(`isMobile=${isMobile}`);

    const sessionOverlayId = sessionOverlay.triggerStickyOverlay();
    if (sessionOverlayId) {
        params.push(`echo=${sessionOverlayId}`);
    }

    params = urlUtility.pushLandingPageParams(params);

    for (const overlayId of getOncePerVisitOverlayList()) {
        params.push(`so=${overlayId}`);
    }
    for (const overlayId of getOnceEverOverlayList()) {
        params.push(`oo=${overlayId}`);
    }

    if (config.overlayKeyValue && typeof config.overlayKeyValue == 'object') {
        const cleanOverlayKeyValue = {}
        for (const key of Object.keys(config.overlayKeyValue)) {
            if (typeof config.overlayKeyValue[key] == 'string') {
                cleanOverlayKeyValue[key] = config.overlayKeyValue[key];
            } else {
                console.warn(
                    `Value provided for custom overlay targeting key ${key}` +
                    ` is of type ${typeof config.overlayKeyValue[key]} and will be ignored. ` +
                    'Values used for custom overlay targeting must be of string type only.'
                );
            }
        }
        params.push(`okv=${encodeURIComponent(JSON.stringify(cleanOverlayKeyValue))}`);
    }

    return params;
}

function buildOverlayUrl() {
    const domainAndPath = `${config.personalizeDomain}/v1/personalize/initialize?`;

    let params = getUrlParameters();

    params = urlUtility.pushUserIdKeyAndUserIdValue(config, params);

    return Promise.resolve(domainAndPath + params.join('&'));
}

function setPreview() {
    if (urlUtility.isPreview()) {
        isPreview = true;
    }
    return isPreview;
}

const defaultFn = (options) => {
    config = options;
    trackOverlay = trackOverlayInit(config);
    integration = integrationInit(config);
    urlUtility = urlUtil();
    sessionOverlay = sessionOverlayInit();
    timeOverlay = timeOverlayInit(displayPersonalizedOverlayHTML);

    return {
        hideOverlay,
        buildOverlayUrl,
        renderOverlays,
        renderOverlay,
        isShowOverlaySet,
        setPreview,
        getUrlParameters,
    };
};

export default defaultFn;
